import React from 'react';
import { bool, node, shape, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCardVertical } from './recomendations-card';

const PolycardContext = loadable(() => import('../polycard/polycardContext'));
const Polycard = loadable(() => import('../polycard/polycard'));
Polycard.preload();

const measuringHeightLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringHeight,
});

const measuringHeight = measuringHeightLoader.preload();

const measuringFlagLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringFlag,
});

const measuringFlag = measuringFlagLoader.preload();

/* istanbul ignore next */
const ContextTemplate = ({ contextValue, type, thousandSeparator, decimalSeparator, children }) => (
  <PolycardContext
    contextValue={{ ...contextValue, type }}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
  >
    {children}
  </PolycardContext>
);

const CardTemplate = ({ isPolycard, ...cardProps }) =>
  cardProps.metadata ? (
    <Polycard polycard={{ ...cardProps }} className="recos-polycard" />
  ) : (
    <RecommendationsCardVertical {...cardProps} />
  );

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const measuringFunctions = isPolycard ? { measuringHeight, measuringFlag } : {};
  return (
    <RecommendationsCarouselSnapped
      Card={CardTemplate}
      IconFactory={RecommendationsIconFactory}
      Context={ContextTemplate}
      contextProps={{
        type: 'grid-card',
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      {...measuringFunctions}
      {...props}
      className={`${props.className} recos-override__card-vertical`}
    />
  );
};

ContextTemplate.propTypes = {
  children: node,
  contextValue: shape({}),
  type: string,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

CardTemplate.propTypes = {
  isPolycard: bool,
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
};

export default Recommendations;
